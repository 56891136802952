$brand-dark-grey: #454c53;
$brand-med-grey: #666b6f;
$brand-light-grey: #e9ebee;
$brand-grey-borders: #cdcdcd;
$brand-light-blue: #e3f5ff;
$brand-blue: #048edd;
$brand-green: #10ab5b;
$brand-orange: #ffb786;
$brand-red: #d44b00;

$darkGrey: $brand-dark-grey;
$medGrey: $brand-med-grey;
$lightGrey: $brand-light-grey;
$grey: $brand-grey-borders;
$borderColor: $brand-grey-borders;
$lightBlue: $brand-light-blue;
$newNotificationBg: $lightBlue;
$green: $brand-green;
$blue: $brand-blue;
$orange: $brand-orange;
$warn: $brand-red;
$primary: $blue;

$primaryShadow: 1px 1px 6.86px 0.14px rgba(0, 0, 0, 0.16);
$btnShadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.16);
