@import '../../../shared/_vars';

.statistics-refresh {
  float: right;
}

.meun-item-icon-fontsize {
  font-size: 15px !important;
  margin-bottom: -3px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.meun-item-txt {
  display: inline !important;
}

.db-container {
  display: flex;
  width: 100%;

  .db-side-cards {
    margin-right: 10px;
    width: 100px;
    height: 265px;

    .side-control-btn {
      height: 100px;
      width: 100px;
      margin-bottom: 15px;

      svg {
        font-size: 20px !important;
      }

      > div {
        border-radius: 1px;
        border-width: 1px;
        padding: 15px 0 !important;
      }
    }
  }

  .db-grid {
    .db-columns {
      .db-columns-card {
        height: calc(100vh - 205px);
        overflow-y: hidden;
        overflow-x: hidden;

        .column-card-header {
          height: 155px;
          padding-left: 15px !important;
          padding-right: 15px !important;
          white-space: nowrap !important;

          span:first-child {
            color: $primary;
          }

          .header-edit-table-btn {
            margin-right: 10px !important;
          }
        }

        .column-card-content {
          padding-left: 10px !important;
          padding-right: 10px !important;
          margin-top: -30px !important;
          height: calc(100% - 200px) !important;

          .db-column-list {
            padding-right: 10px !important;

            button {
              text-transform: unset !important;
            }
          }
        }
      }
    }

    .hidden {
      display: none;
    }

    .db-column-info {
      .db-column-info-card {
        height: calc(100vh - 205px);

        .db-info-header {
          height: 50px;
          white-space: nowrap !important;
          border-bottom: 1px solid #e9ebee !important;
        }

        .db-info-content {
          height: calc(100% - 50px);
          padding-left: 15px !important;
          padding-right: 10px !important;

          .db-info-grid-container {
            .db-column-form-side {
              position: relative;

              .collapse-btn {
                float: right !important;
              }
            }
          }
        }
      }
    }
  }
}
