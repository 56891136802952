@import '../shared/vars';
.drawer-bar .active {
    /* position: relative;
    right: 0;  
    height: 100%; */
    // border-right: 5px solid #048edd;
}

.drawer-bar * {
    color: $darkGrey !important;
    svg {
        margin-right: 0px !important;
        font-size: 20px !important;
    }
    div {
        padding: 0px 10px !important;
    }
}

.drawer-bar .active * {
    color: $blue !important;
}

.main-app-bar {
    a {
        font-size: 14px !important;
        border-radius: 25px;
        svg {
            font-size: 20px !important;
        }
    }
    a.active {
        border-radius: 25px; // border: 1.5px solid white;
        background: #067bbe;
        &:hover {
            background: #056eaa !important
        }
    }
}

.main-app-bar {
    box-shadow: unset !important;
}

.fullHeight {
    height: 100%;
}