$group-left-padding: 10px;
$group-border-color: rgb(212, 75, 0);
$group-tree-line-color: $group-border-color;
$group-border-width: 27px;

$child-left-margin: 15px;
$condition-border-color: rgb(16, 187, 139);
$condition-border-width: 15px;
$last-condition-border-left: 17px;

$glue-btn-height: 25px;
$glue-btn-width: 45px;

$panel-background-color: white;

$glue-btn: #048edd;

.group-panel {
  border-left: 2px solid $group-border-color;
  padding-top: $glue-btn-height - 5px;
  background: $panel-background-color;
  padding-left: $group-left-padding;
  margin: 20px $child-left-margin;
  position: relative;
  margin-right: 0;

  .group-bar {
    position: absolute !important;
    top: -2px;
    left: -$glue-btn-width/2;
    font-size: 12px;
    width: calc(100% + 17px);
    text-align: right;
  }

  .btn {
    border-radius: $glue-btn-height/2;
    min-width: $glue-btn-width;
    height: $glue-btn-height;
    border: none;
    cursor: pointer;
  }

  .glue-btn {
    background: $glue-btn;
    position: absolute;
    color: white;
    left: 0;
    text-align: center;
    padding: 5px;
    z-index: 2;
  }

  .add-condition {
    color: $condition-border-color;
    background: none;
  }

  .add-group {
    color: $group-border-color;
    background: none;
  }

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    width: $group-border-width;
    left: -$group-border-width;
    height: 2px;
    background: $group-tree-line-color;
    z-index: 1;
  }

  // &:hover {
  //   border-color: darken($group-border-color, 20) !important;
  // }

  &:first-child {
    &:before {
      display: none;
    }
  }

  .condition-panel {
    margin: 10px 5px;
    border-left-width: 2px;
    position: relative;
    margin-right: 0;

    &:before {
      display: block;
      position: absolute;
      content: '';
      top: 50%;
      width: $condition-border-width;
      left: -$condition-border-width;
      height: 2px;
      background: $condition-border-color;
    }
  }

  & > .condition-panel,
  & > .group-panel {
    &:last-child {
      position: relative;
      &:after {
        display: block;
        position: absolute;
        content: '';
        width: 5px;
        height: 50%;
        bottom: -2px;
        background: $panel-background-color;
        z-index: 1;
      }
    }
  }

  & > .condition-panel {
    &:last-child {
      &:after {
        left: -$condition-border-width - 2;
      }
    }
  }

  & > .group-panel {
    &:last-child {
      &:after {
        left: -$group-border-width - 2;
      }
    }
  }
}
