// @import 'assets/oi-icns8-icons/styles.min';
@import 'shared/_vars';
// Add react-grid-layout style
@import '../node_modules/react-grid-layout/css/styles.css';
@import '../node_modules/react-resizable/css/styles.css';

html,
body {
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #eaebef;
  color: $brand-dark-grey;
}

#root {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-center {
  text-align: center;
}

@import 'shared/_styles';

.flex {
  flex: 1;
}

.spacer {
  flex: '1 1 100%';
}

.input-margin {
  width: 31% !important; // margin-left: 8px !important;
  margin-right: 15px !important;
}

.btn-rounded {
  border-radius: 25px !important;
  box-shadow: none !important;
}

.width-100 {
  width: 100px !important;
}

.full-width {
  width: 100%;
}

.flex-direction-column {
  flex-direction: column;
}

.kpi-chart-bg {
  background-image: url('assets/img/kpi-bg.png');
  background-size: cover;
}

.rounded-outlined-input {
  border: 1px solid $lightGrey !important;
  border-radius: 30px !important;
  padding: 0px 15px !important;
  margin-top: 7px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  transition: border 0.2s linear !important;

  svg {
    color: $brand-blue;
  }
}

.rounded-outlined-input-foucs {
  border: 2px solid $brand-blue !important;
  transition: border-color 0.2s linear !important;
}

.rounded-outlined-input:last-child {
  margin-right: 0px !important;
}

.rounded-outlined-input:first-child {
  margin-left: 0px !important;
}

table > tbody > tr:nth-child(odd) {
  background: #f7f7f7;
}
