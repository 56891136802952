@import '../../../shared/_vars';

.add-questions-popover {
  max-width: 500px !important;
  min-width: 150px !important;
  height: 100%;
  top: 0px !important;
  // left: -20px !important;
  padding-top: 10px;

  .no-questions {
    padding: 30px !important;
    text-align: center;
  }

  .add-questions-popover {
    padding-top: 10px;
  }

  .questions-list {
    height: 470px;
    width: 470px;
    overflow-x: hidden;
    overflow-y: auto;

    #search-field {
      width: 100%;
    }

    .virtualized-list :focus {
      outline: none !important;
    }

    li {
      cursor: pointer;
      padding: 0px 20px;
      position: relative;

      .add-icon {
        display: none;
        position: absolute;
        right: 20px;
        top: 0;
      }

      &:hover {
        background-color: $brand-light-blue;

        .add-icon {
          display: block;
        }
      }
    }
  }
}

.dashboard-tabs-appbar {
  position: sticky;
  top: 0px;
  z-index: 1400;
  right: 0px;
  background-color: #fff;

  &.dashboard-tabs-appbar {
    box-shadow: $primaryShadow;
  }

  .add-question-btn {
    align-items: center;
    text-transform: none;
    margin: auto;
    margin-right: 20px;
    padding: 0 15px;
  }

  .tabs {
    align-items: center;
    align-content: center;
    min-height: 44px !important;
    padding: 10px;
    padding-right: 0;

    // display: flex;
    .tab {
      display: flex;

      &:hover {
        border-radius: 25px;
        background: #f1f1f1;
      }
    }

    .selected {
      border-radius: 25px;
      background: #048edd;

      &:hover {
        border-radius: 25px;
        background: #048edd;
      }

      .tabs-title-content {
        p {
          color: #fff !important;
        }

        // &:hover {
        //   .title-btns {
        //     display: inline-flex !important;
        //   }
        // }

        .title-btns {
          // display: none;
          display: flex !important;

          button {
            color: #fff !important;

            &:hover {
              background-color: #ffffff;
              color: #048edd !important;
              border: none !important;
            }
          }

          .btn-disabled {
            color: #ffffff5e !important;
            border: none !important;
          }
        }
      }
    }

    .add-tab-btn {
      align-self: center;
    }

    > div:nth-child(2) > div:last-child {
      flex-basis: 20px;
    }

    .tabs-title-content {
      padding: 2px 10px;
      display: inline-flex;
      width: 100%;
      align-items: center;
      align-content: center;

      p {
        margin: 0px 10px;
      }

      .title-input {
        width: 100%;
        color: inherit;

        input {
          color: #000;
        }

        .editable-input {
          input {
            border-radius: 10px;
            padding: 6px 15px;
            background: #fff;
          }
        }
      }

      .title-btns {
        // display: flex;
        width: fit-content;
        height: 36px;
        display: none;
      }
    }
  }
}

.dashbaord-create-wrapper {
  display: inline-flex;
  width: 100%;
  margin-top: 5px;
  height: calc(100vh - 180px);

  .grid {
    .card-wrapper {
      width: 100%;
      height: calc(100% - 15px);
      overflow: hidden;

      .card-content-swipeable {
        width: 100%;
        height: 100%;

        .grid-layout {
          height: 100%;
          width: 100%;
        }

        .data-grid {
          // border: 1px solid #f7f7f7;
          box-shadow: 1px 1px 6.86px 0.14px rgba(0, 0, 0, 0.16) !important;
        }

        .data-preview {
          overflow: auto;
          width: 100%;
          height: 100%;
          background: #fff;

          .tableWrapper {
            height: calc(100% - 55px) !important;
          }

          .btnsToTheLeft {
            .previewResult-title {
              font-size: 14px !important;
            }
          }

          .btnsToTheRight {
            button {
              height: 36px !important;
              width: 36px !important;
            }
          }
        }

        .react-grid-layout {
          overflow: hidden;
        }
      }

      .add-questions-btn {
        position: fixed;
        bottom: 80px;
        right: 40px;
      }
    }

    .tabs-input {
      pointer-events: none;
    }
  }

  .oi-dimensions-ref {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .preview-wrap {
    height: calc(100% - 70px);
  }
}

.react-resizable-handle {
  height: 10px !important;
  width: 10px !important;
}
