@import './_vars';
// @import './inputs';

.main-shadow {
  box-shadow: $primaryShadow !important;
}

.pull-end {
  display: flex;
  margin: auto;
  margin-right: 0;
}

.padding-20 {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
  flex: 1;
}

.scrollbar {
  height: 100%;
  width: 100%;
}

.chart-actions-menu {
  .oi-icon {
    margin-right: 10px;
  }
}
