$mediaQuery: 800px;

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.powered-by {
  width: 20px;
  height: 20px;
  position: absolute;
  width: 195px;
  height: 28px;
  bottom: 0;
  right: 0;
  background: url(../assests/landing-imgs/Powered_by_IQVIA_RGB.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.registration-main-contaier {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f5f5;

  .assista-header {
    width: 160px;
    height: 30px;
    background: url(../assests/registration/ocean_logo_black.png);
    position: absolute;
    left: 20px;
    top: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }

  .circle-1 {
    position: fixed;
    width: 500px;
    height: 500px;
    /* max-width: 400px; */
    /* max-width: inherit; */
    max-height: 500px;
    bottom: -201px;
    left: -100px;
    /* border: 1px solid #148f95; */
    background: #18a0db8f;
    /* border-top-left-radius: 70%; */
    /* border-bottom-right-radius: 70%; */
    border-radius: 50%;
    margin-left: 20p;
    transition: all 0.2s linear;
    animation: fadein 2s;
  }

  .circle-2 {
    position: fixed;
    width: 500px;
    height: 500px;
    max-height: 500px;
    bottom: -164px;
    left: -32px;
    /* border: 1px solid blue; */
    background: #18a0dbab;
    border-radius: 50%;
    z-index: 0;
    animation: fadein 4s;
  }

  .circle-3 {
    position: fixed;
    width: 500px;
    height: 500px;
    /* max-width: 400px; */
    /* max-width: inherit; */
    max-height: 500px;
    top: -214px;
    right: -100px;
    background: #18a0dbbf;
    /* border-top-left-radius: 70%; */
    /* border-bottom-right-radius: 70%; */
    border-radius: 50%;
    margin-left: 20p;
    animation: fadein 2s;
  }

  .circle-4 {
    position: fixed;
    width: 500px;
    height: 500px;
    max-height: 500px;
    top: -187px;
    right: -152px;
    /* border: 1px solid blue; */
    background: #0267a0c9;
    border-radius: 50%;
    z-index: 0;
    animation: fadein 2s;
  }

  .registration-container {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 970px;
    max-height: 635px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: $mediaQuery) {
      max-height: 100%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }

    // input:-internal-autofill-previewed,
    // input:-internal-autofill-selected,
    // textarea:-internal-autofill-previewed,
    // textarea:-internal-autofill-selected,
    // select:-internal-autofill-previewed,
    // select:-internal-autofill-selected {
    //     background-color: transparent !important;
    //     background: transparent !important;
    // }

    .registration-panel-container {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 970px;
      max-height: 600px;
      background: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 4px;
      overflow: hidden;

      @media (max-width: $mediaQuery) {
        max-height: 100%;
      }

      .registration-form-cover {
        background: url(../assests/landing-imgs/Assista_Cover_RGB.png);
        width: 40%;
        position: absolute;
        right: 0;
        height: 100%;
        background-size: 445px 730px;
        background-repeat: no-repeat;
        background-position: 0 0;
        animation: fadein 2s;
        background-color: #18a0db;

        @media (max-width: $mediaQuery) {
          width: 100% !important;
          position: relative !important;
          background-size: cover !important;
          height: 50%;
        }

        .cover-text {
          animation: fadein 2s;
          transform: translate(-50%, -50%);
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;

          .registration-logo {
            width: 312px;
            height: 57px;
            background: url(../assests/registration/ocean_logo_white2.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin: 0px auto;
          }

          .text {
            text-align: center;
            color: white;
            font-size: 20px;
            width: 322px;
            margin: 10px auto;
          }
        }
      }

      .registration-form-panel {
        width: 60%;
        position: absolute;
        right: left;
        height: 100%;
        background: white;

        @media (max-width: $mediaQuery) {
          width: 100% !important;
          position: relative !important;
          background-size: cover !important;
          height: 50%;
        }

        .registration-inputs-panel {
          max-width: 305px;
          width: 100%;
          margin: 0 auto;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .inputs-header {
            font-size: 30px;

            .header-title {
              // color: #606569;
              font-size: 30px;
              font-weight: bold;
            }
          }

          .select-container {
            position: relative;
            color: #eaebef;
            border: 1px solid #eaebef;
            height: 40px;
            overflow: hidden;
            border-radius: 100px;

            &.country {
              .flag {
                width: 50px;
                background: white;
                left: 0;
                z-index: 1;
                height: 50px;
                padding-top: 18px;

                img {
                  width: 25px;
                  height: 25px;
                  margin-left: 16px;
                  position: absolute;
                  top: 7px;
                  left: 0;
                }
              }

              mat-select {
                width: calc(100% - 70px);
                right: 10px;
              }
            }
          }
        }

        button {
          width: 50%;
          height: 40px;
          border-radius: 50px;
          border: 1px solid #18a0db;
          margin-bottom: 10px;
          /* padding-left: 30px; */
          background: #18a0db;
          color: white;
          font-size: 13px;
          cursor: pointer;
          margin-top: 20px;
          transition: width 0.2s ease, height 1s ease;
          outline: none;
          font-size: 16px;

          &:hover {
            width: 100% !important;
            transition: width 0.2s ease, height 1s ease;
          }
        }

        a {
          color: #12a4d8;
          text-decoration: none;
        }

        .term-of-service {
          color: #606569;
        }
      }

      .join-message {
        width: 567px;
        margin: 65px auto;
        text-align: center;

        .message-header {
          .assista-logo {
            width: 300px;
            height: 75px;
            margin: 0 auto;
            background: url(../assests/landing-imgs/Assista_Color_RGB.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }

        .message {
          color: #12a4d8;
          font-size: 18px;
          font-weight: 600;
        }

        .message-logo {
          background: url(../assests/landing-imgs/message.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 230px;
          width: 275px;
          margin: 0 auto;
        }

        .message-note {
          color: #035587;
          font-size: 18px;

          .contact-us {
            color: #12a4d8;
            text-decoration: none;
          }
        }
      }
    }
  }
}
